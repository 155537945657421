import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import InformativeDashboard from "./pages/InformativeDashboard";
import CDWCard from "./pages/CDW/CDWCard";
import QCCard from "pages/QC/QCCard";
import GamesFeature from "pages/GamesFeature";
import CheckGames from "pages/CheckGames.js";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-green-500`;
  const HighlightedText = tw.span`text-green-500`;
document.title="RASITGR Games | Red Apple Swimming In The Green River Games"
  return (
    <>
      <CDWCard />
     
      <CheckGames />
     
      {/* <GamesFeature /> */}
     
      <InformativeDashboard />

      <MainFeature2
        subheading={<Subheading>SÜRDÜRÜLEBİLİR</Subheading>}
        heading={
          <>
            Oyun geliştirme <HighlightedText>Prensiplerimiz</HighlightedText>
          </>
        }
        description={
          "Bi oyunu; oynamak için hayal eder, mühendis olarak planlar, sanatçı olarak betimler, oyuncu olarak dener, halk olarak eleştirir, alıcı olarak fiyatlandırırız. "
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Bütçe Dostu",
            description:
              "Fiyatlandırma söz konusu olduğunda önceliğimiz oyunumuza herkesin erişebilmesi. Diğer oyun fikirlerimizi de üretip herkesle paylaşmak için gereken asgari tutarın üzerine çıkmıyoruz. RASITGR Games'de kâr etmek için değil neşemizi ve yeni oyunlarımızı herkesle paylaşmak için oyun geliştiriyoruz.",
            iconContainerCss: tw`bg-green-300 text-green-800`,
          },
          {
            Icon: BriefcaseIcon,
            title: "Tam Donanımlı",
            description:
              "RASITGR Games olarak oyun geliştirirken en çağdaş yöntemleri en içten fikirlerle harmanlıyoruz. Bunu da sürdürülebilir ve geliştirilebilir tekniklerle yapıyoruz. Beğendiğiniz bi oyunun bizim tarafımızdan geliştirirmemesi söz konusu değil, bunu garanti ediyoruz.",
            iconContainerCss: tw`bg-red-300 text-red-800`,
          },
        ]}
      />
    </>
  );
};
