import React from 'react'
import CardQC from 'pages/CDW/CDWCard';
import CardCDW from 'pages/QC/QCCard';
import CardPZ from 'pages/PZ/PZCard';
import AnimationRevealPage from 'helpers/AnimationRevealPage';

function Games() {
  document.title="Oyunlar | RASITGR Games"
  return (
    <AnimationRevealPage>
        <CardQC/>
        <CardCDW/>
        <CardPZ/>
        
    </AnimationRevealPage>
  )
}

export default Games