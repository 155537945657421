import AnimationRevealPage from "helpers/AnimationRevealPage";
import React from "react";
import Team from "pages/OurTeam";
import Method from "pages/HowWeMake";
import Start from "pages/HowWeStart";
import Testers from "pages/Testers.js";

function AboutUs() {
 document.title="Hakkımızda | RASITGR Games"
  return (
    <AnimationRevealPage>
      <Start />
      <Method />
      {/* <Team />
      <Testers /> */}
    </AnimationRevealPage>
  );
}

export default AboutUs;
