import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-green-500 text-gray-100 p-1 rounded-full group-hover:bg-green-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

export default ({
  subheading = "",
  heading = "Kurallar",
  description = "Oynadığımız sayısız test oyunu sırasında bizi birbirimize düşürmekten alıkoyacak bazı kurallar belirlememiz gerekti. ",
  imageSrc = "https://images.unsplash.com/photo-1579427421635-a0015b804b2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  imageContain = false,
  imageShadow = true,
  faqs = null
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "Sadece Yandaş kartları ile set tamamlanamaz",
      answer:
        "Bir setin tamamlanması için, o sete ait en az bir topluluk kartı bulunmalıdır. Sadece Yandaş Kartlarından oluşan setler kabul edilmez."
    },
    {
      question: "Tamamlanmış setler bozulamaz",
      answer:
        "Ödeme yaparken set bozmak son ihtimaldir. Bir oyuncunun önünde alternatif olarak PG değeri olan herhangi bir kart bulunması durumunda tamamlanmış bir seti kendi dahi bozamaz. Yandaş kartlarına yönelik Politik Kartlar, rakiplerin tamamlanmış setlerine karşı kullanılamaz, yani tamamlanmış setler bu kartlarla da bozulamaz. Sadece Yandaş Kartları yerine uygun set kartı yerleştirilebilir ve bahsi geçen yandaş kartı boşa çıkartılır."
    },
    {
      question:"Politik kartlar propaganda gücüne dönüştürülebilir",
      answer:"İç tarafındaki yeşil çizgiden ayırt edilebilen Politik Kartların tamamı propaganda gücüne dönüştürülebilir. Bu kartlar tur boyunca geri politik karta dönüştürülemez."
    },
    {
      question: "Para üstü alınmaz",
      answer:
        "Talep edilen PG değerinde ödeme yapılırken para üstü verilmeyeceğini göz önünde bulundurun. Örnek olarak, sizden 1PG değerinde bile ödeme talep edilse, önünüzdeki en düşük PG'li kartı vermekle yükümlüsünüz. Para üstü talep edemezsiniz."
    },
    {
      question:"Topluluk kartları ile ödeme yapılabilir",
      answer:"Oyuncular başka kartları olmadığında veya kendi istekleri doğrultusunda ödemelerini önlerindeki topluluk kartlarıyla yapabilir. Bu durumda, topluluk kartlarının Oy Gücü, Propaganda Gücü yerine geçmektedir."
    },
    {
      question:"Önünde kart olmayan oyuncu ödeme yapmaz",
      answer:"Oyuncuların önünde PG değeri taşıyan hiçbir kart olmaması durumunda ödeme yapmakla yükümlü olmazlar. Elden ödeme yapmak söz konusu değildir."
    },
    {
      question: "0PG (Sıfır Propaganda Gücü) her borcu öder",
      answer:
        "PG değeri “0” olan kartlar, her borcu öder. “0” birim ödeme istendiğinde, herkes öncelikle elindeki 0PG kartı teslim etmekle yükümlüdür. 0PG ödeme istendiğinde sahada 0PG kartınız yoksa elinizdeki en büyük kartı ödemekle yükümlüsünüz."
    },
    {
      question: "En fazla 7 kart tutabilirsiniz",
      answer:"Her oyuncunun elinde en fazla 7 kart bulunabilir. Elinde olağanüstü bir şekilde 7 kart bulunan oyuncular kart sayısını 7'nin altına düşürene kadar yeni kart çekemezler."
    },
    {
      question: "Deste bittiğinde kartlar karılır ve yeni deste oluşturulur",
      answer:"Tüm deste oynandığında ve oyun hala bitmediğinde ıskartaya çıkmış ve tamamlanmamış set kartları birleştirilip, karılıp yeni deste oluşturulur ve oyun devam eder. Bu süreçte oyuncular ellerindeki kartları saklama veya ıskartaya çıkarma hakkına sahiptir. İsteyen oyuncular ellerindeki kartları da desteye ekleyip yeni bir başlangıç yapabilirler. Yeni seçimde oyunu kazanmak için gereken Oy Gücü’ne 1 eklenir. Oyuncuların yerleştirdiği tamamlanmamış yani set olmamış Topluluk ve Yandaş kartlarının tamamı toplanır ve ortadaki desteye eklenir. Oyunun bir kez daha bitmemesi durumunda Oy Gücü’ne 1 puan eklenmez. Linç Karalama kartı herhangi bir oyuncunun o ana kadar biriktirdiği Propaganda Birikiminin üzerine eklenir ve o birikimi, aklanana kadar etkisiz kılar. Oyunun uzaması ve kartların yeniden dağıtılması söz konusu olduğunda karalanmış Propaganda Birikimi hala Ak Süt kartıyla kurtarılmadıysa bu birikim de yeni desteye katılır."
    },
    {
      question: "Her tur azami 4 kart oynanabilir",
      answer: "Oyuncular her el azami 4 kart oynayabilir. Bu 4 kartın 2’si Politik 2’si Topluluk kartı olacak şekilde dağıtılmalıdır. Ancak, sadece Politik veya sadece Topluluk Kartı oynamak isteyen oyuncular, sadece bir türden 3 karta kadar oynayabilir (ö. 3 Topluluk kartı veya 3 Politik Kart).",
    },
    {
      question:"Karalama kartları yerleştirildiği yere bağlanır",
      answer:"Karalama Kartları herhangi bir sete veya propaganda birikimine bağlanır. Eğer bahsi geçen set bozulursa Karalama Kartı ıskartaya düşer."
    },
    {
      question:"Sadece Yandaşlarla miting yapılamaz",
      answer:"Miting Kartları sadece Yandaş Kartlarından oluşan kart grupları ile kullanılamaz. En az 1 Topluluk Kartı olan kart grupları ile kullanılabilir."
    },
    {
      question:"Karalama kartı koz olarak önceden oynanabilir",
      answer:"Karalama kartları, 1 hamle harcayarak kapalı şekilde oyuncuların önünde koz olarak kullanılabilir. Oyuncu istediği zaman bu kart ile bir rakibini karalayabilir."
    }
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <Content>
        <TwoColumn>
          {/* <Column tw="hidden lg:block w-5/12 flex-shrink-0">
            <Image imageContain={imageContain} imageShadow={imageShadow} imageSrc={imageSrc} />
          </Column> */}
          <Column>
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      {faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
