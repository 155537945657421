import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../../components/misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:"https://drive.google.com/uc?export=view&id=1wfdjWuqoHuljTtk_DJxabNe54Pa8YsAN",
       
      subtitle: "Önce",
      title: "Mental durumunu değerlendir",
      description:
        "Günü planlamadan önce durumları değerlendirmelisiniz. Cengiz'i eğiterek öz farkındalığını artırabilirsiniz. Öz farkındalığı artan Cengiz size kendi hakkında daha çok bilgi verecektir.",
      url: "https://timerse.com"
    },

    {
      imageSrc:
        "https://drive.google.com/uc?export=view&id=14WliVslETwarQiZj5VLWg2I3lQfjncBi",
      subtitle: "Sonra",
      title: "Gününü planla",
      description:
        "Günü planlarken her aktivitenin gerçek hayatta 20dk Cengiz'in hayatında 1 saat süreceğini unutmayın. Aynı aktiviteleri tekrarlamak Cengiz'in bağımlı olmasına sebep olup, mutluluğunu azaltabilir.",
      url: "https://timerse.com"
    },

    {
      imageSrc:
        "https://drive.google.com/uc?export=view&id=1agTCdnLcaSs8zElThVhbf8GR5j0GXHd2",
      subtitle: "Son olarak",
      title: "Sonuçları gözden geçir",
      description:
        "Tamamlanan her aktivite size bir bildirim verecektir. Bu bildirimlerle eylemin nasıl geçtiğini takip edebilir sonraki günleri kafanızda kurmaya başlayabilirsiniz.",
      url: "https://timerse.com"
    }
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Hızlı Oyun Rehberi</HeadingTitle>
          <HeadingDescription>
          Anadolu Rock'ta beş temel aktivite türü vardır. Bunlar; Zaman Öldürme, Bilgilenme, Kişisel Bakım, Online Alışveriş ve Yüz yüze alışveriştir. Bu aktiviteleri gün içine dağıtarak Cengiz'in 45 günlük karantina sürecini yönetmeniz beklenmektedir. Aktivitelerinizi güne dağıtırken göz önünde bulundurmanız gereken mücadele unsurları; Mental Sağlık, Fiziksel Sağlık, Enerji, Açlık, Uyku, Yiyecek ve Su Stokları, Hazır Yemek Stoğu ve bağımlılıklardır. Oyuna başlarkenki zorluk seçimi sorusu, 'Sigara içiyor musun?' oyunun zorluğunu belirlerken bahsi geçen mücadele unsurlarını başlıca olumsuz etkiler. Her aktivite gerçek hayatta 20 dakika sürmektedir ve listeye aynı anda azami gerçek hayatta 12 saat sürecek kadar aktivite ekleyebilirsiniz

          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc}  />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
